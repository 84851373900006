<template>
  <div>
    <!-- leads-table-area  -->
    <div class="leads-table-area addcustomer-table">
      <table id="leads_table" class="display nowrap">
        <thead>
          <tr>
            <th>Lead ID</th>
            <th>Customer</th>
            <th>Assigned Users</th>
            <th>Email From</th>
            <th>Created at</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody></tbody>
      </table>
    </div>
    <!-- leads-table-area  -->
    <!-- Raw Lead XML Popup -->
    <div
      class="modal fade"
      id="showXml"
      tabindex="-1"
      role="dialog"
      aria-labelledby="leadXml"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="leadXml">Raw Lead XML</h5>
            <div>
              <a class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true"
                  ><img
                    class="img-fluid"
                    src="../../assets/img/23. Closex.svg"
                    alt=""
                /></span>
              </a>
              <a class="copy close mr-2" aria-label="Copy">
                <span aria-hidden="true"
                  ><svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="far"
                    data-icon="copy"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                    width="15px"
                    height="15px"
                  >
                    <path
                      d="M433.941 65.941l-51.882-51.882A48 48 0 0 0 348.118 0H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-48h80c26.51 0 48-21.49 48-48V99.882a48 48 0 0 0-14.059-33.941zM266 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h74v224c0 26.51 21.49 48 48 48h96v42a6 6 0 0 1-6 6zm128-96H182a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h106v88c0 13.255 10.745 24 24 24h88v202a6 6 0 0 1-6 6zm6-256h-64V48h9.632c1.591 0 3.117.632 4.243 1.757l48.368 48.368a6 6 0 0 1 1.757 4.243V112z"
                    ></path></svg
                ></span>
              </a>
            </div>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <pre>{{ xml }}</pre>
                <div><input type="hidden" class="xml-data-to-copy" :value="xml" ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Raw Lead XML Popup -->
  </div>
</template>
<script>
import axios from "axios";
import $ from "jquery";
import XmlBeautify from "xml-beautify";
import "datatables.net";
import "datatables.net-responsive";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-responsive-dt/css/responsive.dataTables.min.css";
import "../../assets/css/dataTables.css";
export default {
  name: "Rawleads",
  components: {},
  data() {
    return {
      xml: null,
    };
  },
  created() {},
  methods: {
    getRawLeads() {
      $("#leads_table")
        .addClass("nowrap")
        .dataTable({
          order: [[1, "desc"]],
          responsive: true,
          fixedHeader: true,
          processing: true,
          serverSide: true,
          searching: false,
          ajax: {
            url: axios.defaults.baseURL + "rawleads",
            type: "post",
            data: function (response) {
              console.log(response.data);
            },
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          },
          columns: [
            { data: "lead_link" },
            { data: "customer_link" },
            { data: "user_name" },
            { data: "from_email" },
            { data: "created_at" },
            { data: "open_model" },
          ],
        });
    }
  },
  mounted() {
    this.getRawLeads();
    const thisObject = this;
    $(document).on("click", ".openXmlDialogModel", function () {
      var id = $(this).data("id");
      $("#showXml").modal("show");
      axios
        .get("/getXmlData/" + id, {
          headers: {
            Authorization: "Bearer " + thisObject.$storage.get("auth").token,
          },
        })
        .then(function (response) {
          console.log(response.data);
          thisObject.xml = new XmlBeautify({ parser: DOMParser }).beautify(response.data.data);
        })
        .catch(function (error) {
          console.log(error);
          $("#showXml").modal("hide");
        });
    });

    $(document).on("click", ".copy", function () {
      let testingCodeToCopy = document.querySelector(".xml-data-to-copy");
      console.log(testingCodeToCopy);
      testingCodeToCopy.setAttribute("type", "text");
      testingCodeToCopy.select();

      try {
        var successful = document.execCommand("copy");
        var msg = successful ? "successful" : "unsuccessful";
        console.log(msg);
      } catch (err) {
        alert("Oops, unable to copy");
      }

      /* unselect the range */
      testingCodeToCopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    });
  },
}
</script>
<style scoped>
.modal-dialog {
  max-width: 1100px;
}
.data_table_link {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
}
</style>
